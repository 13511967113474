<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div class="teamOne">{{ $t("ourTeam.team_tipOne") }}</div>
      <!--<div class="teamTwo">{{ $t("ourTeam.team_tipTwo") }}：</div>
      <div class="teamList">
        <div class="teamItem" v-for="(item, index) in team" :key="index">
          <img :src="item.image" alt="" class="itemImg" />
          <div class="itemCont">
            <div class="contOne">
              <div class="name">{{ item.name }}</div>
              <div class="position">{{ item.zhic }}</div>
            </div>
            <div class="xian"></div>
            <div class="contTwo">{{ item.descript }}</div>
          </div>
        </div>
      </div> -->

      <div
        class="tipItem"
        v-for="(item, index) in $t('ourTeam.team_tipList')"
        :key="index"
      >
        <img :src="item.icon" alt="" />
        <div>{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：关于我们-我们的团队
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-12 15:46
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "关于我们",
          title_en: "About us",
          path: "aboutUs",
        },
        {
          title_zh: "我们的团队",
          title_en: "Our team",
          path: "",
        },
      ],

      team: [],
    };
  },
  mounted() {
    this.getMyTeam();
  },
  methods: {
    /**
     * 我的团队
     * 刘嘉鑫
     * 2022-8-19
     */
    getMyTeam() {
      this.$request({
        url: "/Index/teamList",
        data: {},
      }).then((res) => {
        this.team = res.team_list;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  background: #1a2a60;
  padding: 60px 310px;
}
.teamOne {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 60px;
}
.teamTwo {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 40px;
}

.teamList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 37px;
  .teamItem {
    width: 41%;
    background: #ffffff;
    padding: 38px;
    display: flex;
    margin-bottom: 40px;
    .itemImg {
      width: 131px;
      height: 164px;
      border-radius: 8px;
      margin-right: 40px;
    }

    .itemCont {
      padding: 12px 0;

      .contOne {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        .name {
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #222222;
          margin-right: 14px;
        }

        .position {
          background: #07257d;
          border-radius: 4px;
          padding: 6px 10px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .xian {
        width: 32px;
        height: 4px;
        background: #dddddd;
        margin-bottom: 30px;
      }

      .contTwo {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #222222;
      }
    }
  }
}

.tipItem {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  > img {
    width: 53px;
    height: 50px;
    margin-right: 60px;
  }
  > div {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }
}

.tipItem:last-child{
  margin-bottom: 0 !important;
}
</style>